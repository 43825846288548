<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Karyawan</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="id_departement">Departement </label>
                  <select
                    v-model="form.id_departement"
                    type="text"
                    class="form-control"
                    required
                    @change="formValidate.id_departement = ''"
                    :class="{
                      'is-valid': form.id_departement != '',
                      'is-invalid':
                        formValidate.id_departement ||
                        form.id_departement == '',
                    }"
                  >
                    <option value="">-Pilih-</option>
                    <option
                      v-for="departement in listDepartemen"
                      :key="departement.id_departement"
                      :value="departement.id_departement"
                    >
                      {{ departement.nama_departement }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="username_karyawan"
                    >Username Karyawan
                  </label>
                  <input
                    v-model="form.username_karyawan"
                    type="text"
                    class="form-control"
                    placeholder="Input Username Karyawan"
                    required
                    @input="formValidate.username_karyawan = ''"
                    :class="{
                      'is-valid': form.username_karyawan != '',
                      'is-invalid':
                        formValidate.username_karyawan ||
                        form.username_karyawan == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="email_karyawan"
                    >Email Karyawan
                  </label>
                  <input
                    v-model="form.email_karyawan"
                    type="email"
                    class="form-control"
                    placeholder="Input Email Karyawan"
                    required
                    @input="formValidate.email_karyawan = ''"
                    :class="{
                      'is-valid': form.email_karyawan != '',
                      'is-invalid':
                        formValidate.email_karyawan ||
                        form.email_karyawan == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="nohp_karyawan"
                    >No. HP Karyawan
                  </label>
                  <input
                    v-model="form.nohp_karyawan"
                    type="tel"
                    class="form-control"
                    placeholder="Input No. HP Karyawan"
                    required
                    @input="formValidate.nohp_karyawan = ''"
                    :class="{
                      'is-valid': form.nohp_karyawan != '',
                      'is-invalid':
                        formValidate.nohp_karyawan || form.nohp_karyawan == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="nama_karyawan">Nama Karyawan</label>
                  <input
                    v-model="form.nama_karyawan"
                    type="text"
                    class="form-control"
                    placeholder="Input Nama Karyawan"
                    required
                    @input="formValidate.nama_karyawan = ''"
                    :class="{
                      'is-valid': form.nama_karyawan != '',
                      'is-invalid':
                        formValidate.nama_karyawan || form.nama_karyawan == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="password_karyawan"
                    >Password Karyawan
                  </label>
                  <input
                    v-model="form.password_karyawan"
                    type="password"
                    class="form-control"
                    placeholder="Input Password Karyawan"
                    required
                    @input="formValidate.password_karyawan = ''"
                    :class="{
                      'is-valid': form.password_karyawan != '',
                      'is-invalid':
                        formValidate.password_karyawan ||
                        form.password_karyawan == '',
                    }"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="bold" for="role_karyawan"
                    >Posisi Karyawan
                  </label>
                  <div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        value="A"
                        v-model="form.role_karyawan"
                        id="radioA"
                      />
                      <label for="radioA">Administrator</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        value="E"
                        v-model="form.role_karyawan"
                        id="radioE"
                      />
                      <label for="radioE">Engineer</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        value="K"
                        v-model="form.role_karyawan"
                        id="radioK"
                      />
                      <label for="radioK">Koordinator</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        value="S"
                        v-model="form.role_karyawan"
                        id="radioS"
                      />
                      <label for="radioS">SPV</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
              :disabled="loading"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import userData from "../../../plugins/userData";

export default {
  mixins: [userData],
  data() {
    return {
      form: {
        id_karyawan: "",
        id_departement: "",
        username_karyawan: "",
        email_karyawan: "",
        nohp_karyawan: "",
        nama_karyawan: "",
        password_karyawan: "",
        role_karyawan: "",
      },
      listDepartemen: [],
      formValidate: [],
      loading: false,
    };
  },
  mounted() {
    this.getDepartemen();
  },
  methods: {
    getDepartemen() {
      axios
        .get(this.$url+"/v2/departemen/")
        .then((response) => {
          this.listDepartemen = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      axios
        .post(this.$url+"/v2/karyawan/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Input form tidak lengkap !",
              });
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>